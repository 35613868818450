class MobileMenu {
  constructor() {
    this.navEl = document.querySelector(".nav");
    this.navLogo = document.querySelector(".nav-logo");
    this.navDeskBtn = document.querySelector(".nav-desk-open-search");
    this.navDeskSearch = document.querySelector(".nav-desk-search");
    this.events();
  }

  events() {
    this.navLogo?.addEventListener("click", () => this.openMobileMenu());
    this.navLogo?.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.openMobileMenu();
      }
    });
    this.navDeskBtn?.addEventListener("click", (e) => this.openDeskSearch(e));
  }

  openMobileMenu() {
    if (this.navEl.className === "nav") {
      this.navEl.className += " show";
    } else if (this.navEl.className === "nav show") {
      this.navEl.className = "nav noshow";
    } else {
      this.navEl.className = "nav show";
    }
  }

  openDeskSearch(e) {
    e.preventDefault();
    this.navDeskBtn.classList.toggle("open");
    this.navDeskSearch.classList.toggle("open");
  }
}

export default MobileMenu;
