class OpenComment {
  constructor() {
    this.commentBtn = document.querySelector(".post-comments-btn");
    this.commentArea = document.querySelector(".post-comments-area");
    this.btnComment = document.querySelector(".post-comments-btn");
    this.commentNumber =
      document.querySelector(".post-comments-list") &&
      Number(document?.querySelector(".post-comments-list").dataset.comments);
    this.commentManage = false;
    this.event();
  }
  event() {
    this.commentBtn?.addEventListener("click", () => this.openCommentSection());
    this.commentBtn?.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        this.openCommentSection();
      }
    });
  }

  openCommentSection() {
    this.commentArea.classList.toggle("open");
    this.commentManage = !this.commentManage;
    if (this.commentManage) {
      this.btnComment.innerHTML = "fermer les commentaires";
    } else {
      this.btnComment.innerHTML =
        this.commentNumber === 0
          ? "laisser un commentaire"
          : this.commentNumber === 1
          ? "voir le commentaire"
          : `voir les ${this.commentNumber} commentaires`;
    }
  }
}

export default OpenComment;
