class StartScrap {
  constructor() {
    //SCRAP NEW IDS
    this.formScrap = document.getElementById("form-scrap");
    this.submitScrap = document.getElementById("start-scrap");

    //SCRAP OLD IDS
    this.formOldScrap = document.getElementById("form-old-scrap");
    this.submitOldScrap = document.getElementById("start-old-scrap");

    //CLEAN IDS
    this.formDelete = document.getElementById("form-delete-ads");
    this.submitDelete = document.getElementById("delete-ads");

    this.spinnerScrap = document.querySelector(".spinner-scrapping");
    this.spinnerClean = document.querySelector(".spinner-cleaning");
    this.events();
  }
  events() {
    this.submitScrap?.addEventListener("click", (e) =>
      this.scrapNewPropertiesRoute(e)
    );
    this.submitOldScrap?.addEventListener("click", (e) =>
      this.scrapOldPropertiesScrapRoute(e)
    );
    this.submitDelete?.addEventListener("click", (e) =>
      this.callDeleteRoute(e)
    );
  }

  async scrapNewPropertiesRoute(e) {
    console.log("SCRAP ADS");
    console.log(new Date().getMinutes());
    this.spinnerScrap.style.display = "inline-block";
    e.preventDefault();
    let formData = new FormData(this.formScrap);

    fetch(
      optiblogData.root_url +
        "/wp-json/data/v1/local-scrap-new-properties-rightmove",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        this.spinnerScrap.style.display = "none";
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Parse the JSON content of the response
        return response.json();
      })
      .then((responseData) => {
        console.log(new Date().getMinutes());
        console.log("ALL GOOD");
        console.log(responseData);
      })
      .catch((error) => {
        this.spinnerScrap.style.display = "none";
        console.error("Fetch error:", error);
        // Handle error display or other actions as needed
      });
  }

  async scrapOldPropertiesScrapRoute(e) {
    console.log("SCRAP OLD ADS");
    console.log(new Date().getMinutes());
    this.spinnerScrap.style.display = "inline-block";
    e.preventDefault();
    let formData = new FormData(this.formOldScrap);

    fetch(
      optiblogData.root_url +
        "/wp-json/data/v1/local-scrap-old-properties-rightmove",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        this.spinnerScrap.style.display = "none";
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log(response);
        // Parse the JSON content of the response
        return response.json();
      })
      .then((responseData) => {
        console.log(new Date().getMinutes());
        console.log("ALL GOOD");
        console.log(responseData);
      })
      .catch((error) => {
        this.spinnerScrap.style.display = "none";
        console.error("Fetch error:", error);
        // Handle error display or other actions as needed
      });
  }

  callDeleteRoute(e) {
    console.log("DELETE ADS");
    this.spinnerClean.style.display = "inline-block";

    e.preventDefault();
    let formData = new FormData(this.formDelete);

    const deleteUrl =
      optiblogData.root_url + "/wp-json/data/v1/local-delete-ads/";
    fetch(deleteUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        this.spinnerScrap.style.display = "none";
        console.log(response);
        if (!response.ok) {
          this.spinnerScrap.style.display = "none";
          throw new Error("Network response not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        this.spinnerClean.style.display = "none";
        console.log(responseData);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
      });
  }
}

export default StartScrap;
