import "../css/style.scss";

// Our modules / classes
import MobileMenu from "./modules/MobileMenu";
import OpenComment from "./modules/OpenComment";
import StartScrap from "./modules/StartScrap";

// Instantiate a new object using our modules/classes
const mobileMenu = new MobileMenu();
const openComment = new OpenComment();
const startScrap = new StartScrap();
